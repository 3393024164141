<template>
  <div />
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
    setup(){
        console.log('1111');
    }
});
</script>